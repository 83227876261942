import '@css/main.scss';
import '@js/bootstrap';
import {Header} from "@js/utils/Header";
import {Slider} from "@js/utils/Slider";
import {Filters} from "@js/utils/Filters";
import {Woocommerce} from "@js/utils/Woocommerce";
import {Footer} from "@js/utils/Footer";


window.onload = () => {
    new Header()
    new Slider()
    new Filters()
    new Woocommerce()
    new Footer()
}
